import Splitting from "splitting";

import { $, $$ } from "@utils/dom";

import lineSvg from '../../svg/line.svg';
import circleSvg from '../../svg/circle.svg';

const splitByWordsForMaskAnimation = (el, options, ctx) => {
  const { words } = ctx;
  words.forEach(word => word.innerHTML = `<span class="wordText">${word.innerHTML}</span>`);

  return [];
};

const writeLineTotalInTargetCssVar = (el, options, ctx) => {
  if(el.dataset.scrollTarget){
    $(el.dataset.scrollTarget).style.setProperty('--line-total', ctx.lines.length);
  }

  return [];
};

const addWordsHighlights = (el, options, ctx) => {
  if(el.dataset.wordHighlights){
    const { words } = ctx;
    const wordHighlights = JSON.parse(el.dataset.wordHighlights);
    const highlightMarkup = {line: lineSvg, circle: circleSvg};

    words.forEach(word => {
      for (var index in wordHighlights) {
        let _word = wordHighlights[index].word;
        let _type = wordHighlights[index].type;

        if(word.textContent == _word){
          word.classList.add('--highlight');
          word.classList.add(`--highlight-${_type}`);
          word.innerHTML = word.innerHTML + highlightMarkup[_type];
          
        }
      }
    });
  }

  return [];
};

// split by words and wrap each word content into another span to perform masking animation
Splitting.add({
  by: 'wordsMask',
  key: 'wordsMask',
  depends: ['words'],
  split: splitByWordsForMaskAnimation,
});

// split by lines and wrap each word content into another span to perform masking animation
Splitting.add({
  by: 'linesMask',
  key: 'linesMask',
  depends: ['wordsMask', 'lines'],
  split: writeLineTotalInTargetCssVar
});


// split by lines and wrap each word content into another span to perform masking animation
Splitting.add({
  by: 'wordsWithHighlights',
  key: 'wordsWithHighlights',
  depends: ['wordsMask', 'lines'],
  split: addWordsHighlights
});


export default () => {
  [ ...$$('[data-splitting]') ].forEach(el => {
    const splittingMethod = el.dataset.splitting || "wordsMask";

    Splitting({
      target: el,
      by: splittingMethod
    });
  });
}
